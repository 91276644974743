
import {
  nextTick,
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  PropType,
  toRefs,
  watch,
  ref
} from 'vue';
import { init, EChartsOption } from 'echarts';

import resize from '@/components/charts/mixins/resize';
import { RevenueDailyResponse } from '@/services/api';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    revenueList: {
      type: Object as PropType<RevenueDailyResponse['data']['revenueList']>,
      default: () => ({})
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const { revenueList } = toRefs(props);
    const { dateRange } = toRefs(props);
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });
    const setOptions = () => {
      const list = (revenueList.value as RevenueDailyResponse['data']['revenueList']).data || [];

      const date = list.map(({ date }) => date);
      const newRevenue = list.map(({ newRevenue }) => newRevenue);
      const renewRevenue = list.map(({ renewRevenue }) => renewRevenue);
      const usRenewRevenue = list.map(({ usRenewRevenue }) => usRenewRevenue);
      const totalRevenue = list.map(({ newRevenue, renewRevenue, usRenewRevenue }) => newRevenue + renewRevenue + usRenewRevenue);

      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'cross'
          // },
          // padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['New Revenue', 'Renew Revenue', 'US Renew Revenue', 'Total Revenue']
        },
        series: [
          {
            name: 'New Revenue',
            itemStyle: {
              // color: '#FF005A',
              lineStyle: {
                // color: '#FF005A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: newRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Renew Revenue',
            itemStyle: {
              // color: '#FF005A',
              lineStyle: {
                // color: '#FF005A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: renewRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'US Renew Revenue',
            itemStyle: {
              // color: '#FF005A',
              lineStyle: {
                // color: '#FF005A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: usRenewRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Total Revenue',
            itemStyle: {
              // color: '#FF005A',
              lineStyle: {
                // color: '#FF005A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: totalRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          }
        ]
      } as EChartsOption);
    };

    watch(revenueList, () => {
      setOptions();
    });

    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineCharts') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      formatTime
    };
  }
});
