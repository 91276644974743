
import { onMounted, ref } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import RevenueList from './components/RevenueList.vue';
import dayjs from 'dayjs';

import {
  getRevenueDaily,
  RevenueDailyResponse,
  AnalyticQuery
} from '@/services/api';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    RevenueList
  },
  setup() {
    const revenueDaily = ref<RevenueDailyResponse['data']>({});
    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);
    const currentPage = ref(1);
    const sortOrder = ref({
      order: 'desc',
      prop: ''
    });

    const fetchRevenueDaily = async({
      page = 1,
      sort = 'desc',
      orderBy
    }: AnalyticQuery = {}) => {
      currentPage.value = page;

      const { data } = await getRevenueDaily({
        query: {
          // 2021-12-05 2021-12-31
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          page,
          sort,
          orderBy
        }
      });
      revenueDaily.value = data;
    };

    const handlePageChange = (newPage: number) => {
      fetchRevenueDaily({ page: newPage });
    };

    const handleSortChange = (row: any) => {
      sortOrder.value.order = row.order === 'ascending' ? 'asc' : 'desc';
      sortOrder.value.prop = row.prop;
      fetchRevenueDaily({
        page: currentPage.value,
        sort: sortOrder.value.order,
        orderBy: sortOrder.value.prop
      });
    };

    // 原組件使用function
    // const setDateRange = (_dateRange: string[]) => {
    //   dateRange.value = _dateRange;
    //   fetchRevenueDaily();
    // };

    const setDateRange = () => {
      fetchRevenueDaily();
    };

    onMounted(async() => {
      fetchRevenueDaily();
    });

    return {
      dateRange,
      fetchRevenueDaily,
      handlePageChange,
      revenueDaily,
      setDateRange,
      handleSortChange
    };
  }
};
